<template>
  <body-layout>
    <div class="actions flex" slot="actions">
      <a-button
        type="danger"
        class="mr-3"
        @click="batchDel"
        v-if="selectedRowKeys.length"
        icon="delete"
        v-has="'system:coefficient:delete'"
        >删除</a-button
      >
      <a-button
        type="default"
        class="mr-3"
        @click="batchCopy"
        icon="copy"
        v-if="selectedRowKeys.length"
        v-has="'system:coefficient:edit'"
        >创建副本</a-button
      >
      <a-button icon="plus" type="primary" @click="openAddForm" v-has="'system:coefficient:add'">新建</a-button>
    </div>

    <!-- table区域-begin -->
    <div>
      <a-table
        v-has="'system:font:list:page:view'"
        ref="table"
        size="middle"
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :customRow="clickRow"
        :pagination="ipagination"
        :loading="loading"
        :scroll="{ x: 'max-content', y: 'calc(100vh - 353px)' }"
        :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, columnWidth: 80 }"
        @change="handleTableChange"
      >
        <span slot="ruleType" slot-scope="text, record">{{
          record.ruleType == 'STATISTICS' ? '统计' : record.ruleType == 'SELECT' ? '下拉选项' : ''
        }}</span>
        <span slot="calcLogic" slot-scope="text, record">{{
          record.calcLogic == 'FIXED'
            ? '固定值'
            : record.calcLogic == 'AVERAGE_VALUABLE'
            ? '平均值(除以单独取值话数)'
            : record.calcLogic == 'AVERAGE_FIXED'
            ? '平均值(除以固定话数)'
            : record.calcLogic == 'SUM'
            ? '总和'
            : ''
        }}</span>
        <span slot="calcRange" slot-scope="text, record">{{ getCalcRange(record.calcRange) }}</span>
        <span slot="calcTargetId" slot-scope="text, record">{{ getCalcTarget(record.calcTargetId) }}</span>
        <span slot="departmentNames" slot-scope="text, record">
          <a-tag v-for="one in record.departments" :key="one.departId">{{ one.departName }}</a-tag>
        </span>
        <span slot="positionNames" slot-scope="text, record">
          <a-tag v-for="one in record.positions" :key="one.positionId">{{ one.positionName }}</a-tag>
        </span>
      </a-table>
    </div>
    <!-- table区域-end -->
    <coefficient-modal
      :treeData="treeData"
      :open="drawerShow"
      :cur-row="curRow"
      :isForm="isNewForm"
      @closed="drawerClosed"
    />
  </body-layout>
</template>

<script>
import CoefficientModal from './modules/coefficient/coefficient-modal'
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import { getAction } from '@/api/manage'
export default {
  name: 'Coefficient',
  mixins: [JeecgListMixin],
  components: { CoefficientModal },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      getDepartIdsData: [],
      columns: [
        {
          title: '序号',
          dataIndex: 'sort',
          width: 80,
          sorter: false
        },
        {
          title: '大类名称',
          dataIndex: 'bigType',
          width: 200
        },
        {
          title: '小类名称',
          dataIndex: 'smallType',
          width: 200,
          sorter: false
        },
        {
          title: '规则类型',
          dataIndex: 'ruleType',
          scopedSlots: { customRender: 'ruleType' },
          width: 200
        },
        {
          title: '取值目标',
          dataIndex: 'calcTargetId',
          scopedSlots: { customRender: 'calcTargetId' },
          width: 200
        },
        /*         {
          title: '取值范围',
          scopedSlots: { customRender: 'calcRange' },
          dataIndex: 'calcRange',
          width: 100,
        }, */
        {
          title: '计算逻辑',
          dataIndex: 'calcLogic',
          scopedSlots: { customRender: 'calcLogic' }
        }
        /*         {
          title: '计算公式',
          dataIndex: 'formula',
          width: 100,
        }, */
        /*         {
          title: '适用部门/平台',
          scopedSlots: { customRender: 'departmentNames' },
          dataIndex: 'departments',
          width: 150
        }, */
        /*         {
          title: '适用职位',
          scopedSlots: { customRender: 'positionNames' },
          dataIndex: 'positions',
          width: 150
        } */
      ],
      treeData: [
        {
          title: '管理系统',
          value: '0-0',
          key: '0-0',
          showList: [],
          children: [
            {
              title: '作品详情',
              value: 'PRODUCT_DETAIL',
              key: 'PRODUCT_DETAIL',
              showList: [],
              children: [],
              isShow: true
            },
            {
              title: '系数参数',
              value: 'TASK_DETAIL',
              key: 'TASK_DETAIL',
              showList: [],
              children: [],
              isShow: true
            }
          ]
        },
        {
          title: '翻译系统',
          value: '0-1',
          key: '0-1',
          showList: [],
          children: [
            {
              title: '文本框',
              value: 'TEXT_AREA',
              key: 'TEXT_AREA',
              isShow: true
            },
            {
              title: '术语表',
              value: 'TERM',
              key: 'TERM',
              isShow: true
            }
          ]
        }
      ],
      url: {
        syncUser: '/act/process/extActProcess/doSyncUser',
        list: '/factor/page',
        delete: '/factor',
        deleteBatch: '/factor',
        copyBatch: 'factor/copy',
      },
      isInserstCss: true
    }
  },
  computed: {},
  created() {
    this.getProductDetail()
  },
  methods: {
    getCalcTarget(value) {
      let text = ''
      this.treeData.forEach(item => {
        item.children.forEach(i => {
          if (i.children) {
            i.children.forEach(data => {
              if (data.id == value) {
                text = data.title
              }
            })
          }
        })
      })
      return text
    },
    async getProductDetail() {
      const res = await getAction('/productDetail/page', {
        current: 1,
        size: -1,
        domain: 'PRODUCT_DETAIL',
        type: 'GLOBAL'
      })
      if (res.code == 200) {
        this.treeData[0].children[0].children = [...res.data.records]
      }
      const data = await getAction('/productDetail/page', {
        current: 1,
        size: -1,
        domain: 'TASK_DETAIL',
        type: 'GLOBAL'
      })
      if (data.code == 200) {
        this.treeData[0].children[1].children = [...data.data.records]
      }
    }
  }
}
</script>

<style scoped>
@import '~@assets/less/common.less';
</style>
