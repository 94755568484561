var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "body-layout",
    [
      _c(
        "div",
        {
          staticClass: "actions flex",
          attrs: { slot: "actions" },
          slot: "actions",
        },
        [
          _vm.selectedRowKeys.length
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "system:coefficient:delete",
                      expression: "'system:coefficient:delete'",
                    },
                  ],
                  staticClass: "mr-3",
                  attrs: { type: "danger", icon: "delete" },
                  on: { click: _vm.batchDel },
                },
                [_vm._v("删除")]
              )
            : _vm._e(),
          _vm.selectedRowKeys.length
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "system:coefficient:edit",
                      expression: "'system:coefficient:edit'",
                    },
                  ],
                  staticClass: "mr-3",
                  attrs: { type: "default", icon: "copy" },
                  on: { click: _vm.batchCopy },
                },
                [_vm._v("创建副本")]
              )
            : _vm._e(),
          _c(
            "a-button",
            {
              directives: [
                {
                  name: "has",
                  rawName: "v-has",
                  value: "system:coefficient:add",
                  expression: "'system:coefficient:add'",
                },
              ],
              attrs: { icon: "plus", type: "primary" },
              on: { click: _vm.openAddForm },
            },
            [_vm._v("新建")]
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("a-table", {
            directives: [
              {
                name: "has",
                rawName: "v-has",
                value: "system:font:list:page:view",
                expression: "'system:font:list:page:view'",
              },
            ],
            ref: "table",
            attrs: {
              size: "middle",
              rowKey: "id",
              columns: _vm.columns,
              dataSource: _vm.dataSource,
              customRow: _vm.clickRow,
              pagination: _vm.ipagination,
              loading: _vm.loading,
              scroll: { x: "max-content", y: "calc(100vh - 353px)" },
              rowSelection: {
                selectedRowKeys: _vm.selectedRowKeys,
                onChange: _vm.onSelectChange,
                columnWidth: 80,
              },
            },
            on: { change: _vm.handleTableChange },
            scopedSlots: _vm._u([
              {
                key: "ruleType",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _vm._v(
                      _vm._s(
                        record.ruleType == "STATISTICS"
                          ? "统计"
                          : record.ruleType == "SELECT"
                          ? "下拉选项"
                          : ""
                      )
                    ),
                  ])
                },
              },
              {
                key: "calcLogic",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _vm._v(
                      _vm._s(
                        record.calcLogic == "FIXED"
                          ? "固定值"
                          : record.calcLogic == "AVERAGE_VALUABLE"
                          ? "平均值(除以单独取值话数)"
                          : record.calcLogic == "AVERAGE_FIXED"
                          ? "平均值(除以固定话数)"
                          : record.calcLogic == "SUM"
                          ? "总和"
                          : ""
                      )
                    ),
                  ])
                },
              },
              {
                key: "calcRange",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _vm._v(_vm._s(_vm.getCalcRange(record.calcRange))),
                  ])
                },
              },
              {
                key: "calcTargetId",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _vm._v(_vm._s(_vm.getCalcTarget(record.calcTargetId))),
                  ])
                },
              },
              {
                key: "departmentNames",
                fn: function (text, record) {
                  return _c(
                    "span",
                    {},
                    _vm._l(record.departments, function (one) {
                      return _c("a-tag", { key: one.departId }, [
                        _vm._v(_vm._s(one.departName)),
                      ])
                    }),
                    1
                  )
                },
              },
              {
                key: "positionNames",
                fn: function (text, record) {
                  return _c(
                    "span",
                    {},
                    _vm._l(record.positions, function (one) {
                      return _c("a-tag", { key: one.positionId }, [
                        _vm._v(_vm._s(one.positionName)),
                      ])
                    }),
                    1
                  )
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("coefficient-modal", {
        attrs: {
          treeData: _vm.treeData,
          open: _vm.drawerShow,
          "cur-row": _vm.curRow,
          isForm: _vm.isNewForm,
        },
        on: { closed: _vm.drawerClosed },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }